.p-dropdown {
  background: #ffffff;
  border: 1px solid #cbd5e1;
  transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s, outline-color 0.2s;
  border-radius: 6px;
}
.p-dropdown:not(.p-disabled):hover {
  border-color: #94a3b8;
}
.p-dropdown:not(.p-disabled).p-focus {
  outline: 1px solid var(--p-focus-ring-color);
  outline-offset: -1px;
  box-shadow: none;
  border-color: #94a3b8;
}
.p-dropdown.p-dropdown-clearable .p-dropdown-label {
  padding-right: 1.75rem;
}
.p-dropdown .p-dropdown-label {
  background: transparent;
  border: 0 none;
}
.p-dropdown .p-dropdown-label.p-placeholder {
  color: #64748b;
}
.p-dropdown .p-dropdown-label:focus, .p-dropdown .p-dropdown-label:enabled:focus {
  outline: 0 none;
  box-shadow: none;
}
.p-dropdown .p-dropdown-trigger {
  background: transparent;
  color: #94a3b8;
  width: 2.5rem;
  border-top-right-radius: 6px;
  border-bottom-right-radius: 6px;
}
.p-dropdown .p-dropdown-clear-icon {
  color: #94a3b8;
  right: 2.5rem;
}
.p-dropdown.p-invalid.p-component {
  border-color: #f87171;
}

.p-dropdown-panel {
  background: #ffffff;
  color: #334155;
  border: 1px solid #e2e8f0;
  border-radius: 6px;
  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -2px rgba(0, 0, 0, 0.1);
}
.p-dropdown-panel .p-dropdown-header {
  padding: 0.5rem 0.5rem 0 0.5rem;
  border-bottom: 0 none;
  color: #334155;
  background: #ffffff;
  margin: 0 0 0 0;
  border-top-right-radius: 6px;
  border-top-left-radius: 6px;
}
.p-dropdown-panel .p-dropdown-header .p-dropdown-filter {
  padding-right: 1.75rem;
  margin-right: -1.75rem;
}
.p-dropdown-panel .p-dropdown-header .p-dropdown-filter-icon {
  right: 0.75rem;
  color: #94a3b8;
}
.p-dropdown-panel .p-dropdown-items {
  padding: 0.25rem 0.25rem;
}
.p-dropdown-panel .p-dropdown-items .p-dropdown-item {
  margin: 2px 0;
  padding: 0.5rem 0.75rem;
  border: 0 none;
  color: #334155;
  background: transparent;
  transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s, outline-color 0.2s;
  border-radius: 4px;
}
.p-dropdown-panel .p-dropdown-items .p-dropdown-item:first-child {
  margin-top: 0;
}
.p-dropdown-panel .p-dropdown-items .p-dropdown-item.p-highlight {
  color: #1D4ED8;
  background: #EFF6FF;
}
.p-dropdown-panel .p-dropdown-items .p-dropdown-item.p-highlight.p-focus {
  background: rgba(59, 130, 246, 0.24);
}
.p-dropdown-panel .p-dropdown-items .p-dropdown-item:not(.p-highlight):not(.p-disabled).p-focus {
  color: #0f172a;
  background: #e2e8f0;
}
.p-dropdown-panel .p-dropdown-items .p-dropdown-item:not(.p-highlight):not(.p-disabled):hover {
  color: #1e293b;
  background: #f1f5f9;
}
.p-dropdown-panel .p-dropdown-items .p-dropdown-item-group {
  margin: 0;
  padding: 0.5rem 0.75rem;
  color: #94a3b8;
  background: #ffffff;
  font-weight: 600;
}
.p-dropdown-panel .p-dropdown-items .p-dropdown-empty-message {
  padding: 0.5rem 0.75rem;
  color: #334155;
  background: transparent;
}

.p-input-filled .p-dropdown {
  background: #f8fafc;
}
.p-input-filled .p-dropdown:not(.p-disabled):hover {
  background-color: #f8fafc;
}
.p-input-filled .p-dropdown:not(.p-disabled).p-focus {
  background-color: #ffffff;
}
.p-input-filled .p-dropdown:not(.p-disabled).p-focus .p-inputtext {
  background-color: transparent;
}

p-dropdown.ng-dirty.ng-invalid > .p-dropdown {
  border-color: #f87171;
}

.p-dropdown {
  box-shadow: 0 0 #0000, 0 0 #0000, 0 1px 2px 0 rgba(18, 18, 23, 0.05);
}
.p-dropdown .p-dropdown-label {
  box-shadow: none;
}
.p-dropdown.p-disabled {
  opacity: 1;
  background-color: #e2e8f0;
}
.p-dropdown.p-disabled .p-dropdown-label {
  color: #64748b;
}

.p-dropdown-panel .p-dropdown-items .p-dropdown-item .p-dropdown-check-icon {
  margin-left: -0.375rem;
  margin-right: 0.375rem;
}
