.p-tag {
  background: #3B82F6;
  color: #ffffff;
  font-size: 0.75rem;
  font-weight: 700;
  padding: 0.25rem 0.4rem;
  border-radius: 6px;
}
.p-tag.p-tag-success {
  background-color: #22c55e;
  color: #ffffff;
}
.p-tag.p-tag-info {
  background-color: #0ea5e9;
  color: #ffffff;
}
.p-tag.p-tag-warning {
  background-color: #f97316;
  color: #ffffff;
}
.p-tag.p-tag-danger {
  background-color: #ef4444;
  color: #ffffff;
}
.p-tag .p-tag-icon {
  margin-right: 0.25rem;
  font-size: 0.75rem;
}
.p-tag .p-icon {
  width: 0.75rem;
  height: 0.75rem;
}
.p-tag.p-tag-secondary {
  background-color: #f1f5f9;
  color: #475569;
}
.p-tag.p-tag-contrast {
  background-color: #020617;
  color: #ffffff;
}

.p-tag {
  background: var(--primary-100);
  color: var(--primary-500);
}
.p-tag.p-tag-success {
  background: var(--green-100);
  color: var(--green-500);
}
.p-tag.p-tag-info {
  background: var(--blue-100);
  color: var(--blue-500);
}
.p-tag.p-tag-warning {
  background: var(--orange-100);
  color: var(--orange-500);
}
.p-tag.p-tag-danger {
  background: var(--red-100);
  color: var(--red-500);
}
