
::-webkit-input-placeholder {
  color: #64748b;
}

:-moz-placeholder {
  color: #64748b;
}

::-moz-placeholder {
  color: #64748b;
}

:-ms-input-placeholder {
  color: #64748b;
}

label.required-input-label:after {
  content:" *";
  color: #f87171;
}

.p-inputtext {
  font-family: var(--font-family);
  font-feature-settings: var(--font-feature-settings, normal);
  font-size: 0.875rem;
  color: #334155;
  background: #ffffff;
  padding: 0.5rem 0.75rem;
  border: 1px solid #cbd5e1;
  transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s, outline-color 0.2s;
  appearance: none;
  border-radius: 6px;
}
.p-inputtext:enabled:hover {
  border-color: #94a3b8;
}
.p-inputtext:enabled:focus {
  outline: 1px solid var(--p-focus-ring-color);
  outline-offset: -1px;
  box-shadow: none;
  border-color: #94a3b8;
}
.p-inputtext.ng-dirty.ng-invalid {
  border-color: #f87171;
}
.p-inputtext.p-inputtext-sm {
  font-size: 0.75rem;
  padding: 0.4375rem 0.65625rem;
}
.p-inputtext.p-inputtext-lg {
  font-size: 1.125rem;
  padding: 0.625rem 0.9375rem;
}

.p-icon-field .p-input-icon {
  position: absolute;
  top: 50%;
  margin-top: -0.75rem;
}

.p-input-icon-left > .p-icon-wrapper.p-icon,
.p-input-icon-left > i:first-of-type {
  left: 0.5rem;
  color: #94a3b8;
}

.p-icon-field-left .p-input-icon:first-of-type {
  left: 0.5rem;
  color: #6b7280;
}

.p-input-icon-left > .p-inputtext {
  padding-left: 2.5rem;
}

.p-input-icon-left.p-float-label > label {
  left: 2.5rem;
}

.p-input-icon-right > .p-icon-wrapper,
.p-input-icon-right > i:last-of-type {
  right: 0.75rem;
  color: #94a3b8;
}

.p-input-icon-right > .p-inputtext {
  padding-right: 2.5rem;
}

.p-icon-field-left > .p-inputtext {
  padding-left: 2.25rem;
}

.p-icon-field-left.p-float-label > label {
  left: 2.5rem;
}

.p-icon-field-right > .p-inputtext {
  padding-right: 2.5rem;
}

p-inputmask.ng-dirty.ng-invalid > .p-inputtext {
  border-color: #f87171;
}

p-inputmask.p-inputmask-clearable .p-inputtext {
  padding-right: 2.5rem;
}
p-inputmask.p-inputmask-clearable .p-inputmask-clear-icon {
  color: #94a3b8;
  right: 0.75rem;
}

p-inputnumber.ng-dirty.ng-invalid > .p-inputnumber > .p-inputtext {
  border-color: #f87171;
}

p-inputnumber.p-inputnumber-clearable .p-inputnumber-input {
  padding-right: 2.5rem;
}
p-inputnumber.p-inputnumber-clearable .p-inputnumber-clear-icon {
  color: #94a3b8;
  right: 0.75rem;
}

p-inputnumber.p-inputnumber-clearable .p-inputnumber-buttons-stacked .p-inputnumber-clear-icon {
  right: 3.25rem;
}
p-inputnumber.p-inputnumber-clearable .p-inputnumber-buttons-horizontal .p-inputnumber-clear-icon {
  right: 3.25rem;
}

.p-float-label > label {
  left: 0.75rem;
  color: #64748b;
  transition-duration: 0.2s;
}

.p-float-label > .ng-invalid.ng-dirty + label {
  color: #f87171;
}


.p-inputgroup-addon {
  background: #ffffff;
  color: #64748b;
  border-top: 1px solid #cbd5e1;
  border-left: 1px solid #cbd5e1;
  border-bottom: 1px solid #cbd5e1;
  padding: 0.5rem 0.75rem;
  min-width: 2.5rem;
}
.p-inputgroup-addon:last-child {
  border-right: 1px solid #cbd5e1;
}

.p-inputgroup > .p-component,
.p-inputgroup > .p-inputwrapper > .p-inputtext,
.p-inputgroup > .p-float-label > .p-component {
  border-radius: 0;
  margin: 0;
}
.p-inputgroup > .p-component + .p-inputgroup-addon,
.p-inputgroup > .p-inputwrapper > .p-inputtext + .p-inputgroup-addon,
.p-inputgroup > .p-float-label > .p-component + .p-inputgroup-addon {
  border-left: 0 none;
}
.p-inputgroup > .p-component:focus,
.p-inputgroup > .p-inputwrapper > .p-inputtext:focus,
.p-inputgroup > .p-float-label > .p-component:focus {
  z-index: 1;
}
.p-inputgroup > .p-component:focus ~ label,
.p-inputgroup > .p-inputwrapper > .p-inputtext:focus ~ label,
.p-inputgroup > .p-float-label > .p-component:focus ~ label {
  z-index: 1;
}

.p-inputgroup-addon:first-child,
.p-inputgroup button:first-child,
.p-inputgroup input:first-child,
.p-inputgroup > .p-inputwrapper:first-child > .p-component,
.p-inputgroup > .p-inputwrapper:first-child > .p-component > .p-inputtext {
  border-top-left-radius: 6px;
  border-bottom-left-radius: 6px;
}

.p-inputgroup .p-float-label:first-child input {
  border-top-left-radius: 6px;
  border-bottom-left-radius: 6px;
}

.p-inputgroup-addon:last-child,
.p-inputgroup button:last-child,
.p-inputgroup input:last-child,
.p-inputgroup > .p-inputwrapper:last-child > .p-component,
.p-inputgroup > .p-inputwrapper:last-child > .p-component > .p-inputtext {
  border-top-right-radius: 6px;
  border-bottom-right-radius: 6px;
}

.p-inputgroup .p-float-label:last-child input {
  border-top-right-radius: 6px;
  border-bottom-right-radius: 6px;
}

.p-fluid .p-inputgroup .p-button {
  width: auto;
}
.p-fluid .p-inputgroup .p-button.p-button-icon-only {
  width: 2.5rem;
}

.p-inputgroup-addon {
  padding: 0.5rem;
}
