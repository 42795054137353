:root {
  --surface-a: #ffffff; /* Tailwind white */
  --surface-b: #f9fafb; /* Tailwind gray-50 */
  --surface-c: #f3f4f6; /* Tailwind gray-100 */
  --surface-d: #e5e7eb; /* Tailwind gray-200 */
  --surface-e: #ffffff; /* Tailwind white */
  --surface-f: #ffffff; /* Tailwind white */
  --text-color: #4b5563; /* Tailwind gray-600 */
  --text-color-secondary: #6b7280; /* Tailwind gray-500 */
  --primary-color: #3B82F6; /* Tailwind blue-500 */
  --primary-color-text: #ffffff; /* Tailwind white */
  --surface-0: #ffffff; /* Tailwind white */
  --surface-50: #f9fafb; /* Tailwind gray-50 */
  --surface-100: #f3f4f6; /* Tailwind gray-100 */
  --surface-200: #e5e7eb; /* Tailwind gray-200 */
  --surface-300: #d1d5db; /* Tailwind gray-300 */
  --surface-400: #9ca3af; /* Tailwind gray-400 */
  --surface-500: #6b7280; /* Tailwind gray-500 */
  --surface-600: #4b5563; /* Tailwind gray-600 */
  --surface-700: #374151; /* Tailwind gray-700 */
  --surface-800: #1f2937; /* Tailwind gray-800 */
  --surface-900: #111827; /* Tailwind gray-900 */
  --gray-50: #f9fafb; /* Tailwind gray-50 */
  --gray-100: #f3f4f6; /* Tailwind gray-100 */
  --gray-200: #e5e7eb; /* Tailwind gray-200 */
  --gray-300: #d1d5db; /* Tailwind gray-300 */
  --gray-400: #9ca3af; /* Tailwind gray-400 */
  --gray-500: #6b7280; /* Tailwind gray-500 */
  --gray-600: #4b5563; /* Tailwind gray-600 */
  --gray-700: #374151; /* Tailwind gray-700 */
  --gray-800: #1f2937; /* Tailwind gray-800 */
  --gray-900: #111827; /* Tailwind gray-900 */
  --surface-ground: #f9fafb; /* Tailwind gray-50 */
  --surface-section: #ffffff; /* Tailwind white */
  --surface-card: #ffffff; /* Tailwind white */
  --surface-overlay: #ffffff; /* Tailwind white */
  --surface-border: #d1d5db; /* Tailwind gray-300 */
  --surface-hover: #f6f9fc; /* Close to Tailwind gray-50 */
  --focus-ring: 0 0 0 0.2rem #BFDBFE; /* Tailwind blue-300 */
  --maskbg: rgba(0, 0, 0, 0.4);
  --highlight-bg: #EFF6FF; /* Tailwind blue-50 */
  --highlight-text-color: #1D4ED8; /* Tailwind blue-600 */

  --blue-50: #eff6ff; /* Tailwind blue-50 */
  --blue-100: #dbeafe; /* Tailwind blue-100 */
  --blue-200: #bfdbfe; /* Tailwind blue-200 */
  --blue-300: #93c5fd; /* Tailwind blue-300 */
  --blue-400: #60a5fa; /* Tailwind blue-400 */
  --blue-500: #3b82f6; /* Tailwind blue-500 */
  --blue-600: #2563eb; /* Tailwind blue-600 */
  --blue-700: #1d4ed8; /* Tailwind blue-700 */
  --blue-800: #1e40af; /* Tailwind blue-800 */
  --blue-900: #1e3a8a; /* Tailwind blue-900 */
  --green-50: #f0fdf4; /* Tailwind green-50 */
  --green-100: #dcfce7; /* Tailwind green-100 */
  --green-200: #bbf7d0; /* Tailwind green-200 */
  --green-300: #86efac; /* Tailwind green-300 */
  --green-400: #4ade80; /* Tailwind green-400 */
  --green-500: #22c55e; /* Tailwind green-500 */
  --green-600: #16a34a; /* Tailwind green-600 */
  --green-700: #15803d; /* Tailwind green-700 */
  --green-800: #166534; /* Tailwind green-800 */
  --green-900: #14532d; /* Tailwind green-900 */
  --yellow-50: #fefce8; /* Tailwind yellow-50 */
  --yellow-100: #fef9c3; /* Tailwind yellow-100 */
  --yellow-200: #fef08a; /* Tailwind yellow-200 */
  --yellow-300: #fde047; /* Tailwind yellow-300 */
  --yellow-400: #facc15; /* Tailwind yellow-400 */
  --yellow-500: #eab308; /* Tailwind yellow-500 */
  --yellow-600: #ca8a04; /* Tailwind yellow-600 */
  --yellow-700: #a16207; /* Tailwind yellow-700 */
  --yellow-800: #854d0e; /* Tailwind yellow-800 */
  --yellow-900: #713f12; /* Tailwind yellow-900 */
  --cyan-50: #ecfeff; /* Tailwind cyan-50 */
  --cyan-100: #cffafe; /* Tailwind cyan-100 */
  --cyan-200: #a5f3fc; /* Tailwind cyan-200 */
  --cyan-300: #67e8f9; /* Tailwind cyan-300 */
  --cyan-400: #22d3ee; /* Tailwind cyan-400 */
  --cyan-500: #06b6d4; /* Tailwind cyan-500 */
  --cyan-600: #0891b2; /* Tailwind cyan-600 */
  --cyan-700: #0e7490; /* Tailwind cyan-700 */
  --cyan-800: #155e75; /* Tailwind cyan-800 */
  --cyan-900: #164e63; /* Tailwind cyan-900 */
  --pink-50: #fdf2f8; /* Tailwind pink-50 */
  --pink-100: #fce7f3; /* Tailwind pink-100 */
  --pink-200: #fbcfe8; /* Tailwind pink-200 */
  --pink-300: #f9a8d4; /* Tailwind pink-300 */
  --pink-400: #f472b6; /* Tailwind pink-400 */
  --pink-500: #ec4899; /* Tailwind pink-500 */
  --pink-600: #db2777; /* Tailwind pink-600 */
  --pink-700: #be185d; /* Tailwind pink-700 */
  --pink-800: #9d174d; /* Tailwind pink-800 */
  --pink-900: #831843; /* Tailwind pink-900 */
  --indigo-50: #eef2ff; /* Tailwind indigo-50 */
  --indigo-100: #e0e7ff; /* Tailwind indigo-100 */
  --indigo-200: #c7d2fe; /* Tailwind indigo-200 */
  --indigo-300: #a5b4fc; /* Tailwind indigo-300 */
  --indigo-400: #818cf8; /* Tailwind indigo-400 */
  --indigo-500: #6366f1; /* Tailwind indigo-500 */
  --indigo-600: #4f46e5; /* Tailwind indigo-600 */
  --indigo-700: #4338ca; /* Tailwind indigo-700 */
  --indigo-800: #3730a3; /* Tailwind indigo-800 */
  --indigo-900: #312e81; /* Tailwind indigo-900 */
  --teal-50: #f0fdfa; /* Tailwind teal-50 */
  --teal-100: #ccfbf1; /* Tailwind teal-100 */
  --teal-200: #99f6e4; /* Tailwind teal-200 */
  --teal-300: #5eead4; /* Tailwind teal-300 */
  --teal-400: #2dd4bf; /* Tailwind teal-400 */
  --teal-500: #14b8a6; /* Tailwind teal-500 */
  --teal-600: #0d9488; /* Tailwind teal-600 */
  --teal-700: #0f766e; /* Tailwind teal-700 */
  --teal-800: #115e59; /* Tailwind teal-800 */
  --teal-900: #134e4a; /* Tailwind teal-900 */
  --orange-50: #fff7ed; /* Tailwind orange-50 */
  --orange-100: #ffedd5; /* Tailwind orange-100 */
  --orange-200: #fed7aa; /* Tailwind orange-200 */
  --orange-300: #fdba74; /* Tailwind orange-300 */
  --orange-400: #fb923c; /* Tailwind orange-400 */
  --orange-500: #f97316; /* Tailwind orange-500 */
  --orange-600: #ea580c; /* Tailwind orange-600 */
  --orange-700: #c2410c; /* Tailwind orange-700 */
  --orange-800: #9a3412; /* Tailwind orange-800 */
  --orange-900: #7c2d12; /* Tailwind orange-900 */
  --bluegray-50: #f8fafc; /* Tailwind blue-gray-50 */
  --bluegray-100: #f1f5f9; /* Tailwind blue-gray-100 */
  --bluegray-200: #e2e8f0; /* Tailwind blue-gray-200 */
  --bluegray-300: #cbd5e1; /* Tailwind blue-gray-300 */
  --bluegray-400: #94a3b8; /* Tailwind blue-gray-400 */
  --bluegray-500: #64748b; /* Tailwind blue-gray-500 */
  --bluegray-600: #475569; /* Tailwind blue-gray-600 */
  --bluegray-700: #334155; /* Tailwind blue-gray-700 */
  --bluegray-800: #1e293b; /* Tailwind blue-gray-800 */
  --bluegray-900: #0f172a; /* Tailwind blue-gray-900 */
  --purple-50: #faf5ff; /* Tailwind purple-50 */
  --purple-100: #f3e8ff; /* Tailwind purple-100 */
  --purple-200: #e9d5ff; /* Tailwind purple-200 */
  --purple-300: #d8b4fe; /* Tailwind purple-300 */
  --purple-400: #c084fc; /* Tailwind purple-400 */
  --purple-500: #a855f7; /* Tailwind purple-500 */
  --purple-600: #9333ea; /* Tailwind purple-600 */
  --purple-700: #7e22ce; /* Tailwind purple-700 */
  --purple-800: #6b21a8; /* Tailwind purple-800 */
  --purple-900: #581c87; /* Tailwind purple-900 */
  --red-50: #fef2f2; /* Tailwind red-50 */
  --red-100: #fee2e2; /* Tailwind red-100 */
  --red-200: #fecaca; /* Tailwind red-200 */
  --red-300: #fca5a5; /* Tailwind red-300 */
  --red-400: #f87171; /* Tailwind red-400 */
  --red-500: #ef4444; /* Tailwind red-500 */
  --red-600: #dc2626; /* Tailwind red-600 */
  --red-700: #b91c1c; /* Tailwind red-700 */
  --red-800: #991b1b; /* Tailwind red-800 */
  --red-900: #7f1d1d; /* Tailwind red-900 */
  --primary-50: #eff6ff; /* Tailwind primary-50 (blue-50) */
  --primary-100: #dbeafe; /* Tailwind primary-100 (blue-100) */
  --primary-200: #bfdbfe; /* Tailwind primary-200 (blue-200) */
  --primary-300: #93c5fd; /* Tailwind primary-300 (blue-300) */
  --primary-400: #60a5fa; /* Tailwind primary-400 (blue-400) */
  --primary-500: #3b82f6; /* Tailwind primary-500 (blue-500) */
  --primary-600: #2563eb; /* Tailwind primary-600 (blue-600) */
  --primary-700: #1d4ed8; /* Tailwind primary-700 (blue-700) */
  --primary-800: #1e40af; /* Tailwind primary-800 (blue-800) */
  --primary-900: #1e3a8a; /* Tailwind primary-900 (blue-900) */
}

.p-component {
  font-family: var(--font-family);
  font-feature-settings: var(--font-feature-settings, normal);
  font-size: 0.875rem;
  font-weight: normal;
}

.mat-icon {
  overflow: unset !important;
  width: auto !important;
  height: auto !important;
}

.p-icon-field app-spinner.p-input-icon {
  margin-top: -0.7rem;
}

.p-overlaypanel-calendar {
  .p-overlaypanel-content {
    @apply p-0;
  }
  .p-datepicker {
    @apply border-0;
  }
}

@import './buttons';
@import './inputtext';
@import './checkbox';
@import './table';
@import './dropdown';
@import './dialog';
@import './tooltip';
@import './tabview';
@import './multiselect';
@import './card';
@import './calendar';
@import './avatar';
@import './overlay-panel';
@import './badge';
@import './tag';
@import './menu';
@import './autocomplete';
@import './radiobutton';
