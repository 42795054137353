.p-datepicker {
  padding: 0.75rem;
  background: #ffffff;
  color: #334155;
  border: 1px solid #cbd5e1;
  border-radius: 6px;
}
.p-datepicker:not(.p-datepicker-inline) {
  background: #ffffff;
  border: 1px solid #e2e8f0;
  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -2px rgba(0, 0, 0, 0.1);
}
.p-datepicker:not(.p-datepicker-inline) .p-datepicker-header {
  background: #ffffff;
}
.p-datepicker .p-datepicker-header {
  padding: 0 0 0.5rem 0;
  color: #334155;
  background: #ffffff;
  font-weight: 500;
  margin: 0 0 0 0;
  border-bottom: 1px solid #e2e8f0;
  border-top-right-radius: 6px;
  border-top-left-radius: 6px;
}
.p-datepicker .p-datepicker-header .p-datepicker-prev,
.p-datepicker .p-datepicker-header .p-datepicker-next {
  width: 1.75rem;
  height: 1.75rem;
  color: #64748b;
  border: 0 none;
  background: transparent;
  border-radius: 50%;
  transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s, outline-color 0.2s;
}
.p-datepicker .p-datepicker-header .p-datepicker-prev:enabled:hover,
.p-datepicker .p-datepicker-header .p-datepicker-next:enabled:hover {
  color: #475569;
  border-color: transparent;
  background: #f1f5f9;
}
.p-datepicker .p-datepicker-header .p-datepicker-prev:focus-visible,
.p-datepicker .p-datepicker-header .p-datepicker-next:focus-visible {
  outline: 1px solid var(--p-focus-ring-color);
  outline-offset: 2px;
  box-shadow: none;
}
.p-datepicker .p-datepicker-header .p-datepicker-title {
  line-height: 1.75rem;
}
.p-datepicker .p-datepicker-header .p-datepicker-title .p-datepicker-year,
.p-datepicker .p-datepicker-header .p-datepicker-title .p-datepicker-month {
  color: #334155;
  transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s, outline-color 0.2s;
  font-weight: 500;
  padding: 0.25rem;
}
.p-datepicker .p-datepicker-header .p-datepicker-title .p-datepicker-year:enabled:hover,
.p-datepicker .p-datepicker-header .p-datepicker-title .p-datepicker-month:enabled:hover {
  color: #3B82F6;
}
.p-datepicker .p-datepicker-header .p-datepicker-title .p-datepicker-month {
  margin-right: 0.5rem;
}
.p-datepicker table {
  font-size: 1rem;
  margin: 0.5rem 0 0 0;
}
.p-datepicker table th {
  padding: 0.25rem;
}
.p-datepicker table th > span {
  width: 2rem;
  height: 2rem;
}
.p-datepicker table td {
  padding: 0.25rem;
}
.p-datepicker table td > span {
  width: 2rem;
  height: 2rem;
  border-radius: 50%;
  transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s, outline-color 0.2s;
  border: 1px solid transparent;
}
.p-datepicker table td > span.p-highlight {
  color: #1D4ED8;
  background: #EFF6FF;
}
.p-datepicker table td > span:focus {
  outline: 1px solid var(--p-focus-ring-color);
  outline-offset: 2px;
  box-shadow: none;
}
.p-datepicker table td.p-datepicker-today > span {
  background: #e2e8f0;
  color: #0f172a;
  border-color: transparent;
}
.p-datepicker table td.p-datepicker-today > span.p-highlight {
  color: #1D4ED8;
  background: #EFF6FF;
}
.p-datepicker .p-datepicker-buttonbar {
  padding: 0.5rem 0 0 0;
  border-top: 1px solid #e2e8f0;
}
.p-datepicker .p-datepicker-buttonbar .p-button {
  width: auto;
}
.p-datepicker .p-timepicker {
  border-top: 1px solid #e2e8f0;
  padding: 0;
}
.p-datepicker .p-timepicker button {
  width: 1.75rem;
  height: 1.75rem;
  color: #64748b;
  border: 0 none;
  background: transparent;
  border-radius: 50%;
  transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s, outline-color 0.2s;
}
.p-datepicker .p-timepicker button:enabled:hover {
  color: #475569;
  border-color: transparent;
  background: #f1f5f9;
}
.p-datepicker .p-timepicker button:focus-visible {
  outline: 1px solid var(--p-focus-ring-color);
  outline-offset: 2px;
  box-shadow: none;
}
.p-datepicker .p-timepicker button:last-child {
  margin-top: 0.2em;
}
.p-datepicker .p-timepicker span {
  font-size: 1rem;
}
.p-datepicker .p-timepicker > div {
  padding: 0 0.5rem;
}
.p-datepicker.p-datepicker-timeonly .p-timepicker {
  border-top: 0 none;
}
.p-datepicker .p-monthpicker {
  margin: 0.5rem 0 0 0;
}
.p-datepicker .p-monthpicker .p-monthpicker-month {
  padding: 0.25rem;
  transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s, outline-color 0.2s;
  border-radius: 6px;
}
.p-datepicker .p-monthpicker .p-monthpicker-month.p-highlight {
  color: #1D4ED8;
  background: #EFF6FF;
}
.p-datepicker .p-yearpicker {
  margin: 0.5rem 0 0 0;
}
.p-datepicker .p-yearpicker .p-yearpicker-year {
  padding: 0.25rem;
  transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s, outline-color 0.2s;
  border-radius: 6px;
}
.p-datepicker .p-yearpicker .p-yearpicker-year.p-highlight {
  color: #1D4ED8;
  background: #EFF6FF;
}
.p-datepicker.p-datepicker-multiple-month .p-datepicker-group {
  border-left: 1px solid #e2e8f0;
  padding-right: 0.75rem;
  padding-left: 0.75rem;
  padding-top: 0;
  padding-bottom: 0;
}
.p-datepicker.p-datepicker-multiple-month .p-datepicker-group:first-child {
  padding-left: 0;
  border-left: 0 none;
}
.p-datepicker.p-datepicker-multiple-month .p-datepicker-group:last-child {
  padding-right: 0;
}
.p-datepicker:not(.p-disabled) table td span:not(.p-highlight):not(.p-disabled):hover {
  background: #f1f5f9;
}
.p-datepicker:not(.p-disabled) table td span:not(.p-highlight):not(.p-disabled):focus {
  outline: 1px solid var(--p-focus-ring-color);
  outline-offset: 2px;
  box-shadow: none;
}
.p-datepicker:not(.p-disabled) .p-monthpicker .p-monthpicker-month:not(.p-disabled):not(.p-highlight):hover {
  background: #f1f5f9;
}
.p-datepicker:not(.p-disabled) .p-monthpicker .p-monthpicker-month:not(.p-disabled):focus {
  outline: 1px solid var(--p-focus-ring-color);
  outline-offset: 2px;
  box-shadow: none;
}
.p-datepicker:not(.p-disabled) .p-yearpicker .p-yearpicker-year:not(.p-disabled):not(.p-highlight):hover {
  background: #f1f5f9;
}
.p-datepicker:not(.p-disabled) .p-yearpicker .p-yearpicker-year:not(.p-disabled):focus {
  outline: 1px solid var(--p-focus-ring-color);
  outline-offset: 2px;
  box-shadow: none;
}

p-calendar.p-calendar-clearable .p-inputtext {
  padding-right: 2.5rem;
}
p-calendar.p-calendar-clearable .p-calendar-clear-icon {
  color: #94a3b8;
  right: 0.75rem;
}

p-calendar.p-calendar-clearable .p-calendar-w-btn .p-calendar-clear-icon {
  color: #94a3b8;
  right: 3.25rem;
}

@media screen and (max-width: 769px) {
  .p-datepicker table th, .p-datepicker table td {
    padding: 0.25rem;
  }
}

.p-datepicker-buttonbar .p-button {
  padding: 0.25rem 0.75rem;
  font-size: 0.875rem;
}

.p-datepicker .p-datepicker-group-container + .p-timepicker {
  margin-top: 0.5rem;
  padding-top: 0.5rem;
}
.p-datepicker table th {
  font-weight: 500;
}

p-calendar.ng-dirty.ng-invalid > .p-calendar > .p-inputtext {
  border-color: #f87171;
}

.p-calendar:not(.p-calendar-disabled).p-focus > .p-inputtext {
  outline: 1px solid var(--p-focus-ring-color);
  outline-offset: -1px;
  box-shadow: none;
  border-color: #94a3b8;
}
