
.p-multiselect {
  background: #ffffff;
  border: 1px solid #cbd5e1;
  transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s, outline-color 0.2s;
  border-radius: 6px;
}
.p-multiselect:not(.p-disabled):hover {
  border-color: #94a3b8;
}
.p-multiselect:not(.p-disabled).p-focus {
  outline: 1px solid var(--p-focus-ring-color);
  outline-offset: -1px;
  box-shadow: none;
  border-color: #94a3b8;
}
.p-multiselect .p-multiselect-label-empty > .ng-star-inserted:not(:first-child) {
  display: none;
}
.p-multiselect .p-multiselect-label {
  padding: 0.5rem 0.75rem;
  transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s, outline-color 0.2s;
}
.p-multiselect .p-multiselect-label.p-placeholder {
  color: #64748b;
  min-height: 37px;
}
.p-multiselect.p-multiselect-chip .p-multiselect-token {
  padding: 0.25rem 0.75rem;
  margin-right: 0.5rem;
  background: #f1f5f9;
  color: #1e293b;
  border-radius: 16px;
}
.p-multiselect.p-multiselect-chip .p-multiselect-token .p-multiselect-token-icon {
  margin-left: 0.5rem;
}
.p-multiselect .p-multiselect-trigger {
  background: transparent;
  color: #94a3b8;
  width: 2.5rem;
  border-top-right-radius: 6px;
  border-bottom-right-radius: 6px;
}

.p-inputwrapper-filled.p-multiselect.p-multiselect-chip .p-multiselect-label {
  padding: 0.25rem 0.75rem;
}

.p-multiselect-clearable .p-multiselect-label-container {
  padding-right: 1.75rem;
}
.p-multiselect-clearable .p-multiselect-clear-icon {
  color: #94a3b8;
  right: 2.5rem;
}

.p-multiselect-panel {
  background: #ffffff;
  color: #334155;
  border: 1px solid #e2e8f0;
  border-radius: 6px;
  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -2px rgba(0, 0, 0, 0.1);
}
.p-multiselect-panel .p-multiselect-header {
  padding: 0.5rem 0.5rem 0 0.5rem;
  border-bottom: 0 none;
  color: #334155;
  background: #ffffff;
  margin: 0 0 0 0;
  border-top-right-radius: 6px;
  border-top-left-radius: 6px;
}
.p-multiselect-panel .p-multiselect-header .p-multiselect-filter-container .p-inputtext {
  padding-right: 1.75rem;
}
.p-multiselect-panel .p-multiselect-header .p-multiselect-filter-container .p-multiselect-filter-icon {
  right: 0.75rem;
  color: #94a3b8;
}
.p-multiselect-panel .p-multiselect-header .p-checkbox {
  margin-right: 0.5rem;
}
.p-multiselect-panel .p-multiselect-header .p-multiselect-close {
  margin-left: 0.5rem;
  width: 1.75rem;
  height: 1.75rem;
  color: #64748b;
  border: 0 none;
  background: transparent;
  border-radius: 50%;
  transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s, outline-color 0.2s;
}
.p-multiselect-panel .p-multiselect-header .p-multiselect-close:enabled:hover {
  color: #475569;
  border-color: transparent;
  background: #f1f5f9;
}
.p-multiselect-panel .p-multiselect-header .p-multiselect-close:focus-visible {
  outline: 1px solid var(--p-focus-ring-color);
  outline-offset: 2px;
  box-shadow: none;
}
.p-multiselect-panel .p-multiselect-items {
  padding: 0.25rem 0.25rem;
}
.p-multiselect-panel .p-multiselect-items .p-multiselect-item {
  margin: 2px 0;
  padding: 0.5rem 0.75rem;
  border: 0 none;
  color: #334155;
  background: transparent;
  transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s, outline-color 0.2s;
  border-radius: 4px;
}
.p-multiselect-panel .p-multiselect-items .p-multiselect-item:first-child {
  margin-top: 0;
}
.p-multiselect-panel .p-multiselect-items .p-multiselect-item.p-highlight {
  color: #1D4ED8;
  background: #EFF6FF;
}
.p-multiselect-panel .p-multiselect-items .p-multiselect-item.p-highlight.p-focus {
  background: rgba(59, 130, 246, 0.24);
}
.p-multiselect-panel .p-multiselect-items .p-multiselect-item:not(.p-highlight):not(.p-disabled).p-focus {
  color: #0f172a;
  background: #f1f5f9;
}
.p-multiselect-panel .p-multiselect-items .p-multiselect-item:not(.p-highlight):not(.p-disabled):hover {
  color: #1e293b;
  background: #f1f5f9;
}
.p-multiselect-panel .p-multiselect-items .p-multiselect-item .p-checkbox {
  margin-right: 0.5rem;
}
.p-multiselect-panel .p-multiselect-items .p-multiselect-item-group {
  margin: 0;
  padding: 0.5rem 0.75rem;
  color: #94a3b8;
  background: #ffffff;
  font-weight: 600;
}
.p-multiselect-panel .p-multiselect-items .p-multiselect-empty-message {
  padding: 0.5rem 0.75rem;
  color: #334155;
  background: transparent;
}

.p-input-filled .p-multiselect {
  background: #f8fafc;
}
.p-input-filled .p-multiselect:not(.p-disabled):hover {
  background-color: #f8fafc;
}
.p-input-filled .p-multiselect:not(.p-disabled).p-focus {
  background-color: #ffffff;
}

p-multiselect.ng-dirty.ng-invalid > .p-multiselect {
  border-color: #f87171;
}

.p-multiselect-panel .p-multiselect-header {
  padding-left: 1rem;
  padding-right: 1rem;
}

.p-multiselect {
  box-shadow: 0 0 #0000, 0 0 #0000, 0 1px 2px 0 rgba(18, 18, 23, 0.05);
}
.p-multiselect.p-disabled {
  opacity: 1;
  background-color: #e2e8f0;
}
.p-multiselect.p-disabled .p-multiselect-label {
  color: #64748b;
}
.p-multiselect.p-multiselect-chip .p-multiselect-token {
  border-radius: 4px;
  margin-right: 0.25rem;
}
.p-multiselect.p-multiselect-chip .p-multiselect-token .p-multiselect-token-icon {
  margin-left: 0.375rem;
}

.p-inputwrapper-filled .p-multiselect-chip .p-multiselect-label {
  padding: 0.25rem 0.25rem;
}
