.p-button {
  color: #ffffff;
  background: #3B82F6;
  border: 1px solid #3B82F6;
  padding: 0.5rem 1rem;
  font-size: 1rem;
  transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s, outline-color 0.2s;
  border-radius: 6px;
  outline-color: transparent;
}
.p-button:not(:disabled):hover {
  background: #2563eb;
  color: #ffffff;
  border-color: #2563eb;
}
.p-button:not(:disabled):active {
  background: #1D4ED8;
  color: #ffffff;
  border-color: #1D4ED8;
}
.p-button.p-button-outlined {
  background-color: transparent;
  color: #3B82F6;
  border: 1px solid;
}
.p-button.p-button-outlined:not(:disabled):hover {
  background: rgba(59, 130, 246, 0.04);
  color: #3B82F6;
  border: 1px solid;
}
.p-button.p-button-outlined:not(:disabled):active {
  background: rgba(59, 130, 246, 0.16);
  color: #3B82F6;
  border: 1px solid;
}
.p-button.p-button-outlined.p-button-plain {
  color: #334155;
  border-color: #334155;
}
.p-button.p-button-outlined.p-button-plain:not(:disabled):hover {
  background: #f1f5f9;
  color: #334155;
}
.p-button.p-button-outlined.p-button-plain:not(:disabled):active {
  background: #e2e8f0;
  color: #334155;
}
.p-button.p-button-text {
  background-color: transparent;
  color: #3B82F6;
  border-color: transparent;
}
.p-button.p-button-text:not(:disabled):hover {
  background: rgba(59, 130, 246, 0.04);
  color: #3B82F6;
  border-color: transparent;
}
.p-button.p-button-text:not(:disabled):active {
  background: rgba(59, 130, 246, 0.16);
  color: #3B82F6;
  border-color: transparent;
}
.p-button.p-button-text.p-button-plain {
  color: #334155;
}
.p-button.p-button-text.p-button-plain:not(:disabled):hover {
  background: #f1f5f9;
  color: #334155;
}
.p-button.p-button-text.p-button-plain:not(:disabled):active {
  background: #e2e8f0;
  color: #334155;
}
.p-button:focus-visible {
  outline: 1px solid var(--p-focus-ring-color);
  outline-offset: 2px;
  box-shadow: none;
}
.p-button .p-button-label {
  transition-duration: 0.2s;
}
.p-button .p-button-icon-left {
  margin-right: 0.5rem;
}
.p-button .p-button-icon-right {
  margin-left: 0.5rem;
}
.p-button .p-button-icon-bottom {
  margin-top: 0.5rem;
}
.p-button .p-button-icon-top {
  margin-bottom: 0.5rem;
}
.p-button .p-badge {
  margin-left: 0.5rem;
  min-width: 1rem;
  height: 1rem;
  line-height: 1rem;
  color: #3B82F6;
  background-color: #ffffff;
}
.p-button.p-button-raised {
  box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12);
}
.p-button.p-button-rounded {
  border-radius: 2rem;
}
.p-button.p-button-icon-only {
  width: 2.5rem;
  padding: 0.5rem 0;
}
.p-button.p-button-icon-only .p-button-icon-left,
.p-button.p-button-icon-only .p-button-icon-right {
  margin: 0;
}
.p-button.p-button-icon-only.p-button-rounded {
  border-radius: 50%;
  height: 2.5rem;
}

// xsm is not supported by primeng yet. so we use it as styleClass and that's why we have important flag on it
.p-button.p-button-xsm {
  font-size: 0.75rem !important;
  padding: 0.25rem 0.75rem !important;
}
.p-button.p-button-xsm .p-button-icon {
  font-size: 0.875rem !important;
}
///////////

.p-button.p-button-sm {
  font-size: 0.875rem;
  padding: 0.375rem 0.875rem;
}
.p-button.p-button-sm .p-button-icon {
  font-size: 0.875rem;
}
.p-button.p-button-no-padding {
  padding: 0;
}
.p-button.p-button-lg {
  font-size: 1.25rem;
  padding: 0.625rem 1.25rem;
}
.p-button.p-button-lg .p-button-icon {
  font-size: 1.25rem;
}
.p-button.p-button-loading-label-only .p-button-label {
  margin-left: 0.5rem;
}
.p-button.p-button-loading-label-only .p-button-loading-icon {
  margin-right: 0;
}

.p-fluid .p-button {
  width: 100%;
}
.p-fluid .p-button-icon-only {
  width: 2.5rem;
}
.p-fluid .p-button-group {
  display: flex;
}
.p-fluid .p-button-group .p-button {
  flex: 1;
}

.p-button.p-button-secondary, .p-button-group.p-button-secondary > .p-button, .p-splitbutton.p-button-secondary > .p-button {
  color: #475569;
  background: #f1f5f9;
  border: 1px solid #f1f5f9;
}
.p-button.p-button-secondary:not(:disabled):hover, .p-button-group.p-button-secondary > .p-button:not(:disabled):hover, .p-splitbutton.p-button-secondary > .p-button:not(:disabled):hover {
  background: #e2e8f0;
  color: #334155;
  border-color: #e2e8f0;
}
.p-button.p-button-secondary:not(:disabled):focus, .p-button-group.p-button-secondary > .p-button:not(:disabled):focus, .p-splitbutton.p-button-secondary > .p-button:not(:disabled):focus {
  box-shadow: none;
}
.p-button.p-button-secondary:not(:disabled):active, .p-button-group.p-button-secondary > .p-button:not(:disabled):active, .p-splitbutton.p-button-secondary > .p-button:not(:disabled):active {
  background: #cbd5e1;
  color: #1e293b;
  border-color: #cbd5e1;
}
.p-button.p-button-secondary.p-button-outlined, .p-button-group.p-button-secondary > .p-button.p-button-outlined, .p-splitbutton.p-button-secondary > .p-button.p-button-outlined {
  background-color: transparent;
  color: #f1f5f9;
  border: 1px solid;
}
.p-button.p-button-secondary.p-button-outlined:not(:disabled):hover, .p-button-group.p-button-secondary > .p-button.p-button-outlined:not(:disabled):hover, .p-splitbutton.p-button-secondary > .p-button.p-button-outlined:not(:disabled):hover {
  background: rgba(241, 245, 249, 0.04);
  color: #f1f5f9;
  border: 1px solid;
}
.p-button.p-button-secondary.p-button-outlined:not(:disabled):active, .p-button-group.p-button-secondary > .p-button.p-button-outlined:not(:disabled):active, .p-splitbutton.p-button-secondary > .p-button.p-button-outlined:not(:disabled):active {
  background: rgba(241, 245, 249, 0.16);
  color: #f1f5f9;
  border: 1px solid;
}
.p-button.p-button-secondary.p-button-text, .p-button-group.p-button-secondary > .p-button.p-button-text, .p-splitbutton.p-button-secondary > .p-button.p-button-text {
  background-color: transparent;
  color: #f1f5f9;
  border-color: transparent;
}
.p-button.p-button-secondary.p-button-text:not(:disabled):hover, .p-button-group.p-button-secondary > .p-button.p-button-text:not(:disabled):hover, .p-splitbutton.p-button-secondary > .p-button.p-button-text:not(:disabled):hover {
  background: rgba(241, 245, 249, 0.04);
  border-color: transparent;
  color: #f1f5f9;
}
.p-button.p-button-secondary.p-button-text:not(:disabled):active, .p-button-group.p-button-secondary > .p-button.p-button-text:not(:disabled):active, .p-splitbutton.p-button-secondary > .p-button.p-button-text:not(:disabled):active {
  background: rgba(241, 245, 249, 0.16);
  border-color: transparent;
  color: #f1f5f9;
}

.p-button.p-button-info, .p-button-group.p-button-info > .p-button, .p-splitbutton.p-button-info > .p-button {
  color: #ffffff;
  background: #0ea5e9;
  border: 1px solid #0ea5e9;
}
.p-button.p-button-info:not(:disabled):hover, .p-button-group.p-button-info > .p-button:not(:disabled):hover, .p-splitbutton.p-button-info > .p-button:not(:disabled):hover {
  background: #0284c7;
  color: #ffffff;
  border-color: #0284c7;
}
.p-button.p-button-info:not(:disabled):focus, .p-button-group.p-button-info > .p-button:not(:disabled):focus, .p-splitbutton.p-button-info > .p-button:not(:disabled):focus {
  box-shadow: none;
}
.p-button.p-button-info:not(:disabled):active, .p-button-group.p-button-info > .p-button:not(:disabled):active, .p-splitbutton.p-button-info > .p-button:not(:disabled):active {
  background: #0369a1;
  color: #ffffff;
  border-color: #0369a1;
}
.p-button.p-button-info.p-button-outlined, .p-button-group.p-button-info > .p-button.p-button-outlined, .p-splitbutton.p-button-info > .p-button.p-button-outlined {
  background-color: transparent;
  color: #0ea5e9;
  border: 1px solid;
}
.p-button.p-button-info.p-button-outlined:not(:disabled):hover, .p-button-group.p-button-info > .p-button.p-button-outlined:not(:disabled):hover, .p-splitbutton.p-button-info > .p-button.p-button-outlined:not(:disabled):hover {
  background: rgba(14, 165, 233, 0.04);
  color: #0ea5e9;
  border: 1px solid;
}
.p-button.p-button-info.p-button-outlined:not(:disabled):active, .p-button-group.p-button-info > .p-button.p-button-outlined:not(:disabled):active, .p-splitbutton.p-button-info > .p-button.p-button-outlined:not(:disabled):active {
  background: rgba(14, 165, 233, 0.16);
  color: #0ea5e9;
  border: 1px solid;
}
.p-button.p-button-info.p-button-text, .p-button-group.p-button-info > .p-button.p-button-text, .p-splitbutton.p-button-info > .p-button.p-button-text {
  background-color: transparent;
  color: #0ea5e9;
  border-color: transparent;
}
.p-button.p-button-info.p-button-text:not(:disabled):hover, .p-button-group.p-button-info > .p-button.p-button-text:not(:disabled):hover, .p-splitbutton.p-button-info > .p-button.p-button-text:not(:disabled):hover {
  background: rgba(14, 165, 233, 0.04);
  border-color: transparent;
  color: #0ea5e9;
}
.p-button.p-button-info.p-button-text:not(:disabled):active, .p-button-group.p-button-info > .p-button.p-button-text:not(:disabled):active, .p-splitbutton.p-button-info > .p-button.p-button-text:not(:disabled):active {
  background: rgba(14, 165, 233, 0.16);
  border-color: transparent;
  color: #0ea5e9;
}

.p-button.p-button-success, .p-button-group.p-button-success > .p-button, .p-splitbutton.p-button-success > .p-button {
  color: #ffffff;
  background: #22c55e;
  border: 1px solid #22c55e;
}
.p-button.p-button-success:not(:disabled):hover, .p-button-group.p-button-success > .p-button:not(:disabled):hover, .p-splitbutton.p-button-success > .p-button:not(:disabled):hover {
  background: #16a34a;
  color: #ffffff;
  border-color: #16a34a;
}
.p-button.p-button-success:not(:disabled):focus, .p-button-group.p-button-success > .p-button:not(:disabled):focus, .p-splitbutton.p-button-success > .p-button:not(:disabled):focus {
  box-shadow: none;
}
.p-button.p-button-success:not(:disabled):active, .p-button-group.p-button-success > .p-button:not(:disabled):active, .p-splitbutton.p-button-success > .p-button:not(:disabled):active {
  background: #15803d;
  color: #ffffff;
  border-color: #15803d;
}
.p-button.p-button-success.p-button-outlined, .p-button-group.p-button-success > .p-button.p-button-outlined, .p-splitbutton.p-button-success > .p-button.p-button-outlined {
  background-color: transparent;
  color: #22c55e;
  border: 1px solid;
}
.p-button.p-button-success.p-button-outlined:not(:disabled):hover, .p-button-group.p-button-success > .p-button.p-button-outlined:not(:disabled):hover, .p-splitbutton.p-button-success > .p-button.p-button-outlined:not(:disabled):hover {
  background: rgba(34, 197, 94, 0.04);
  color: #22c55e;
  border: 1px solid;
}
.p-button.p-button-success.p-button-outlined:not(:disabled):active, .p-button-group.p-button-success > .p-button.p-button-outlined:not(:disabled):active, .p-splitbutton.p-button-success > .p-button.p-button-outlined:not(:disabled):active {
  background: rgba(34, 197, 94, 0.16);
  color: #22c55e;
  border: 1px solid;
}
.p-button.p-button-success.p-button-text, .p-button-group.p-button-success > .p-button.p-button-text, .p-splitbutton.p-button-success > .p-button.p-button-text {
  background-color: transparent;
  color: #22c55e;
  border-color: transparent;
}
.p-button.p-button-success.p-button-text:not(:disabled):hover, .p-button-group.p-button-success > .p-button.p-button-text:not(:disabled):hover, .p-splitbutton.p-button-success > .p-button.p-button-text:not(:disabled):hover {
  background: rgba(34, 197, 94, 0.04);
  border-color: transparent;
  color: #22c55e;
}
.p-button.p-button-success.p-button-text:not(:disabled):active, .p-button-group.p-button-success > .p-button.p-button-text:not(:disabled):active, .p-splitbutton.p-button-success > .p-button.p-button-text:not(:disabled):active {
  background: rgba(34, 197, 94, 0.16);
  border-color: transparent;
  color: #22c55e;
}

.p-button.p-button-warning, .p-button-group.p-button-warning > .p-button, .p-splitbutton.p-button-warning > .p-button {
  color: #ffffff;
  background: #f97316;
  border: 1px solid #f97316;
}
.p-button.p-button-warning:not(:disabled):hover, .p-button-group.p-button-warning > .p-button:not(:disabled):hover, .p-splitbutton.p-button-warning > .p-button:not(:disabled):hover {
  background: #ea580c;
  color: #ffffff;
  border-color: #ea580c;
}
.p-button.p-button-warning:not(:disabled):focus, .p-button-group.p-button-warning > .p-button:not(:disabled):focus, .p-splitbutton.p-button-warning > .p-button:not(:disabled):focus {
  box-shadow: none;
}
.p-button.p-button-warning:not(:disabled):active, .p-button-group.p-button-warning > .p-button:not(:disabled):active, .p-splitbutton.p-button-warning > .p-button:not(:disabled):active {
  background: #c2410c;
  color: #ffffff;
  border-color: #c2410c;
}
.p-button.p-button-warning.p-button-outlined, .p-button-group.p-button-warning > .p-button.p-button-outlined, .p-splitbutton.p-button-warning > .p-button.p-button-outlined {
  background-color: transparent;
  color: #f97316;
  border: 1px solid;
}
.p-button.p-button-warning.p-button-outlined:not(:disabled):hover, .p-button-group.p-button-warning > .p-button.p-button-outlined:not(:disabled):hover, .p-splitbutton.p-button-warning > .p-button.p-button-outlined:not(:disabled):hover {
  background: rgba(249, 115, 22, 0.04);
  color: #f97316;
  border: 1px solid;
}
.p-button.p-button-warning.p-button-outlined:not(:disabled):active, .p-button-group.p-button-warning > .p-button.p-button-outlined:not(:disabled):active, .p-splitbutton.p-button-warning > .p-button.p-button-outlined:not(:disabled):active {
  background: rgba(249, 115, 22, 0.16);
  color: #f97316;
  border: 1px solid;
}
.p-button.p-button-warning.p-button-text, .p-button-group.p-button-warning > .p-button.p-button-text, .p-splitbutton.p-button-warning > .p-button.p-button-text {
  background-color: transparent;
  color: #f97316;
  border-color: transparent;
}
.p-button.p-button-warning.p-button-text:not(:disabled):hover, .p-button-group.p-button-warning > .p-button.p-button-text:not(:disabled):hover, .p-splitbutton.p-button-warning > .p-button.p-button-text:not(:disabled):hover {
  background: rgba(249, 115, 22, 0.04);
  border-color: transparent;
  color: #f97316;
}
.p-button.p-button-warning.p-button-text:not(:disabled):active, .p-button-group.p-button-warning > .p-button.p-button-text:not(:disabled):active, .p-splitbutton.p-button-warning > .p-button.p-button-text:not(:disabled):active {
  background: rgba(249, 115, 22, 0.16);
  border-color: transparent;
  color: #f97316;
}

.p-button.p-button-help, .p-button-group.p-button-help > .p-button, .p-splitbutton.p-button-help > .p-button {
  color: #ffffff;
  background: #a855f7;
  border: 1px solid #a855f7;
}
.p-button.p-button-help:not(:disabled):hover, .p-button-group.p-button-help > .p-button:not(:disabled):hover, .p-splitbutton.p-button-help > .p-button:not(:disabled):hover {
  background: #9333ea;
  color: #ffffff;
  border-color: #9333ea;
}
.p-button.p-button-help:not(:disabled):focus, .p-button-group.p-button-help > .p-button:not(:disabled):focus, .p-splitbutton.p-button-help > .p-button:not(:disabled):focus {
  box-shadow: none;
}
.p-button.p-button-help:not(:disabled):active, .p-button-group.p-button-help > .p-button:not(:disabled):active, .p-splitbutton.p-button-help > .p-button:not(:disabled):active {
  background: #7e22ce;
  color: #ffffff;
  border-color: #7e22ce;
}
.p-button.p-button-help.p-button-outlined, .p-button-group.p-button-help > .p-button.p-button-outlined, .p-splitbutton.p-button-help > .p-button.p-button-outlined {
  background-color: transparent;
  color: #a855f7;
  border: 1px solid;
}
.p-button.p-button-help.p-button-outlined:not(:disabled):hover, .p-button-group.p-button-help > .p-button.p-button-outlined:not(:disabled):hover, .p-splitbutton.p-button-help > .p-button.p-button-outlined:not(:disabled):hover {
  background: rgba(168, 85, 247, 0.04);
  color: #a855f7;
  border: 1px solid;
}
.p-button.p-button-help.p-button-outlined:not(:disabled):active, .p-button-group.p-button-help > .p-button.p-button-outlined:not(:disabled):active, .p-splitbutton.p-button-help > .p-button.p-button-outlined:not(:disabled):active {
  background: rgba(168, 85, 247, 0.16);
  color: #a855f7;
  border: 1px solid;
}
.p-button.p-button-help.p-button-text, .p-button-group.p-button-help > .p-button.p-button-text, .p-splitbutton.p-button-help > .p-button.p-button-text {
  background-color: transparent;
  color: #a855f7;
  border-color: transparent;
}
.p-button.p-button-help.p-button-text:not(:disabled):hover, .p-button-group.p-button-help > .p-button.p-button-text:not(:disabled):hover, .p-splitbutton.p-button-help > .p-button.p-button-text:not(:disabled):hover {
  background: rgba(168, 85, 247, 0.04);
  border-color: transparent;
  color: #a855f7;
}
.p-button.p-button-help.p-button-text:not(:disabled):active, .p-button-group.p-button-help > .p-button.p-button-text:not(:disabled):active, .p-splitbutton.p-button-help > .p-button.p-button-text:not(:disabled):active {
  background: rgba(168, 85, 247, 0.16);
  border-color: transparent;
  color: #a855f7;
}

.p-button.p-button-danger, .p-button-group.p-button-danger > .p-button, .p-splitbutton.p-button-danger > .p-button {
  color: #ffffff;
  background: #ef4444;
  border: 1px solid #ef4444;
}
.p-button.p-button-danger:not(:disabled):hover, .p-button-group.p-button-danger > .p-button:not(:disabled):hover, .p-splitbutton.p-button-danger > .p-button:not(:disabled):hover {
  background: #dc2626;
  color: #ffffff;
  border-color: #dc2626;
}
.p-button.p-button-danger:not(:disabled):focus, .p-button-group.p-button-danger > .p-button:not(:disabled):focus, .p-splitbutton.p-button-danger > .p-button:not(:disabled):focus {
  box-shadow: none;
}
.p-button.p-button-danger:not(:disabled):active, .p-button-group.p-button-danger > .p-button:not(:disabled):active, .p-splitbutton.p-button-danger > .p-button:not(:disabled):active {
  background: #b91c1c;
  color: #ffffff;
  border-color: #b91c1c;
}
.p-button.p-button-danger.p-button-outlined, .p-button-group.p-button-danger > .p-button.p-button-outlined, .p-splitbutton.p-button-danger > .p-button.p-button-outlined {
  background-color: transparent;
  color: #ef4444;
  border: 1px solid;
}
.p-button.p-button-danger.p-button-outlined:not(:disabled):hover, .p-button-group.p-button-danger > .p-button.p-button-outlined:not(:disabled):hover, .p-splitbutton.p-button-danger > .p-button.p-button-outlined:not(:disabled):hover {
  background: rgba(239, 68, 68, 0.04);
  color: #ef4444;
  border: 1px solid;
}
.p-button.p-button-danger.p-button-outlined:not(:disabled):active, .p-button-group.p-button-danger > .p-button.p-button-outlined:not(:disabled):active, .p-splitbutton.p-button-danger > .p-button.p-button-outlined:not(:disabled):active {
  background: rgba(239, 68, 68, 0.16);
  color: #ef4444;
  border: 1px solid;
}
.p-button.p-button-danger.p-button-text, .p-button-group.p-button-danger > .p-button.p-button-text, .p-splitbutton.p-button-danger > .p-button.p-button-text {
  background-color: transparent;
  color: #ef4444;
  border-color: transparent;
}
.p-button.p-button-danger.p-button-text:not(:disabled):hover, .p-button-group.p-button-danger > .p-button.p-button-text:not(:disabled):hover, .p-splitbutton.p-button-danger > .p-button.p-button-text:not(:disabled):hover {
  background: rgba(239, 68, 68, 0.04);
  border-color: transparent;
  color: #ef4444;
}
.p-button.p-button-danger.p-button-text:not(:disabled):active, .p-button-group.p-button-danger > .p-button.p-button-text:not(:disabled):active, .p-splitbutton.p-button-danger > .p-button.p-button-text:not(:disabled):active {
  background: rgba(239, 68, 68, 0.16);
  border-color: transparent;
  color: #ef4444;
}

.p-button.p-button-contrast, .p-button-group.p-button-contrast > .p-button, .p-splitbutton.p-button-contrast > .p-button {
  color: #ffffff;
  background: #020617;
  border: 1px solid #020617;
}
.p-button.p-button-contrast:not(:disabled):hover, .p-button-group.p-button-contrast > .p-button:not(:disabled):hover, .p-splitbutton.p-button-contrast > .p-button:not(:disabled):hover {
  background: #1e293b;
  color: #ffffff;
  border-color: #1e293b;
}
.p-button.p-button-contrast:not(:disabled):focus, .p-button-group.p-button-contrast > .p-button:not(:disabled):focus, .p-splitbutton.p-button-contrast > .p-button:not(:disabled):focus {
  box-shadow: none;
}
.p-button.p-button-contrast:not(:disabled):active, .p-button-group.p-button-contrast > .p-button:not(:disabled):active, .p-splitbutton.p-button-contrast > .p-button:not(:disabled):active {
  background: #334155;
  color: #ffffff;
  border-color: #334155;
}
.p-button.p-button-contrast.p-button-outlined, .p-button-group.p-button-contrast > .p-button.p-button-outlined, .p-splitbutton.p-button-contrast > .p-button.p-button-outlined {
  background-color: transparent;
  color: #020617;
  border: 1px solid;
}
.p-button.p-button-contrast.p-button-outlined:not(:disabled):hover, .p-button-group.p-button-contrast > .p-button.p-button-outlined:not(:disabled):hover, .p-splitbutton.p-button-contrast > .p-button.p-button-outlined:not(:disabled):hover {
  background: rgba(2, 6, 23, 0.04);
  color: #020617;
  border: 1px solid;
}
.p-button.p-button-contrast.p-button-outlined:not(:disabled):active, .p-button-group.p-button-contrast > .p-button.p-button-outlined:not(:disabled):active, .p-splitbutton.p-button-contrast > .p-button.p-button-outlined:not(:disabled):active {
  background: rgba(2, 6, 23, 0.16);
  color: #020617;
  border: 1px solid;
}
.p-button.p-button-contrast.p-button-text, .p-button-group.p-button-contrast > .p-button.p-button-text, .p-splitbutton.p-button-contrast > .p-button.p-button-text {
  background-color: transparent;
  color: #020617;
  border-color: transparent;
}
.p-button.p-button-contrast.p-button-text:not(:disabled):hover, .p-button-group.p-button-contrast > .p-button.p-button-text:not(:disabled):hover, .p-splitbutton.p-button-contrast > .p-button.p-button-text:not(:disabled):hover {
  background: rgba(2, 6, 23, 0.04);
  border-color: transparent;
  color: #020617;
}
.p-button.p-button-contrast.p-button-text:not(:disabled):active, .p-button-group.p-button-contrast > .p-button.p-button-text:not(:disabled):active, .p-splitbutton.p-button-contrast > .p-button.p-button-text:not(:disabled):active {
  background: rgba(2, 6, 23, 0.16);
  border-color: transparent;
  color: #020617;
}

.p-button.p-button-link {
  color: #2563eb;
  background: transparent;
  border: transparent;
}
.p-button.p-button-link:not(:disabled):hover {
  background: transparent;
  color: #2563eb;
  border-color: transparent;
}
.p-button.p-button-link:not(:disabled):hover .p-button-label {
  text-decoration: underline;
}
.p-button.p-button-link:not(:disabled):focus {
  background: transparent;
  box-shadow: none;
  border-color: transparent;
}
.p-button.p-button-link:not(:disabled):active {
  background: transparent;
  color: #2563eb;
  border-color: transparent;
}

.p-button-label {
  font-weight: 500;
}

.p-button.p-button-success:enabled:focus-visible {
  outline-color: #22c55e;
}
.p-button.p-button-info:enabled:focus-visible {
  outline-color: #0ea5e9;
}
.p-button.p-button-warning:enabled:focus-visible {
  outline-color: #f97316;
}
.p-button.p-button-help:enabled:focus-visible {
  outline-color: #a855f7;
}
.p-button.p-button-danger:enabled:focus-visible {
  outline-color: #ef4444;
}
.p-button.p-button-contrast:enabled:focus-visible {
  outline-color: #020617;
}
.p-button.p-button-outlined {
  border-color: var(--primary-200);
}
.p-button.p-button-outlined:not(:disabled):hover, .p-button.p-button-outlined:not(:disabled):active {
  border-color: var(--primary-200);
}
.p-button.p-button-outlined.p-button-secondary {
  border-color: var(--surface-200);
  color: #64748b;
}
.p-button.p-button-outlined.p-button-secondary:not(:disabled):hover {
  color: #64748b;
  background-color: #f1f5f9;
  border-color: var(--surface-200);
}
.p-button.p-button-outlined.p-button-secondary:not(:disabled):active {
  color: #64748b;
  background-color: #e2e8f0;
  border-color: var(--surface-200);
}
.p-button.p-button-outlined.p-button-success {
  border-color: #bbf7d0;
}
.p-button.p-button-outlined.p-button-success:not(:disabled):hover, .p-button.p-button-outlined.p-button-success:not(:disabled):active {
  border-color: #bbf7d0;
}
.p-button.p-button-outlined.p-button-info {
  border-color: #bae6fd;
}
.p-button.p-button-outlined.p-button-info:not(:disabled):hover, .p-button.p-button-outlined.p-button-info:not(:disabled):active {
  border-color: #bae6fd;
}
.p-button.p-button-outlined.p-button-warning {
  border-color: #fed7aa;
}
.p-button.p-button-outlined.p-button-warning:not(:disabled):hover, .p-button.p-button-outlined.p-button-warning:not(:disabled):active {
  border-color: #fed7aa;
}
.p-button.p-button-outlined.p-button-help {
  border-color: #e9d5ff;
}
.p-button.p-button-outlined.p-button-help:not(:disabled):hover, .p-button.p-button-outlined.p-button-help:not(:disabled):active {
  border-color: #e9d5ff;
}
.p-button.p-button-outlined.p-button-danger {
  border-color: #fecaca;
}
.p-button.p-button-outlined.p-button-danger:not(:disabled):hover, .p-button.p-button-outlined.p-button-danger:not(:disabled):active {
  border-color: #fecaca;
}
.p-button.p-button-outlined.p-button-contrast {
  border-color: #334155;
}
.p-button.p-button-outlined.p-button-contrast:not(:disabled):hover, .p-button.p-button-outlined.p-button-contrast:not(:disabled):active {
  border-color: #334155;
}
.p-button.p-button-secondary.p-button-text {
  color: #64748b;
}
.p-button.p-button-secondary.p-button-text:not(:disabled):hover {
  background: #f1f5f9;
  color: #64748b;
}
.p-button.p-button-secondary.p-button-text:not(:disabled):active {
  background: #e2e8f0;
  color: #64748b;
}
