.p-menu {
  padding: 0.25rem 0.25rem;
  background: #ffffff;
  color: #334155;
  border: 1px solid #e2e8f0;
  border-radius: 6px;
  width: 12.5rem;
}
.p-menu .p-menuitem > .p-menuitem-content {
  color: #334155;
  transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s, outline-color 0.2s;
  border-radius: 4px;
}
.p-menu .p-menuitem > .p-menuitem-content .p-menuitem-link {
  color: #334155;
  padding: 0.5rem 0.75rem;
  user-select: none;
}
.p-menu .p-menuitem > .p-menuitem-content .p-menuitem-link .p-menuitem-text {
  color: #334155;
}
.p-menu .p-menuitem > .p-menuitem-content .p-menuitem-link .p-menuitem-icon {
  color: #94a3b8;
  margin-right: 0.5rem;
}
.p-menu .p-menuitem > .p-menuitem-content .p-menuitem-link .p-submenu-icon {
  color: #94a3b8;
}
.p-menu .p-menuitem.p-highlight > .p-menuitem-content {
  color: #1D4ED8;
  background: #EFF6FF;
}
.p-menu .p-menuitem.p-highlight > .p-menuitem-content .p-menuitem-link .p-menuitem-text {
  color: #1D4ED8;
}
.p-menu .p-menuitem.p-highlight > .p-menuitem-content .p-menuitem-link .p-menuitem-icon,
.p-menu .p-menuitem.p-highlight > .p-menuitem-content .p-menuitem-link .p-submenu-icon {
  color: #1D4ED8;
}
.p-menu .p-menuitem.p-highlight.p-focus > .p-menuitem-content {
  background: rgba(59, 130, 246, 0.24);
}
.p-menu .p-menuitem:not(.p-highlight):not(.p-disabled).p-focus > .p-menuitem-content {
  color: #334155;
  background: #e2e8f0;
}
.p-menu .p-menuitem:not(.p-highlight):not(.p-disabled).p-focus > .p-menuitem-content .p-menuitem-link .p-menuitem-text {
  color: #334155;
}
.p-menu .p-menuitem:not(.p-highlight):not(.p-disabled).p-focus > .p-menuitem-content .p-menuitem-link .p-menuitem-icon,
.p-menu .p-menuitem:not(.p-highlight):not(.p-disabled).p-focus > .p-menuitem-content .p-menuitem-link .p-submenu-icon {
  color: #334155;
}
.p-menu .p-menuitem:not(.p-highlight):not(.p-disabled) > .p-menuitem-content:hover {
  color: #334155;
  background: #f1f5f9;
}
.p-menu .p-menuitem:not(.p-highlight):not(.p-disabled) > .p-menuitem-content:hover .p-menuitem-link .p-menuitem-text {
  color: #334155;
}
.p-menu .p-menuitem:not(.p-highlight):not(.p-disabled) > .p-menuitem-content:hover .p-menuitem-link .p-menuitem-icon,
.p-menu .p-menuitem:not(.p-highlight):not(.p-disabled) > .p-menuitem-content:hover .p-menuitem-link .p-submenu-icon {
  color: #334155;
}
.p-menu.p-menu-overlay {
  background: #ffffff;
  border: 1px solid #e2e8f0;
  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -2px rgba(0, 0, 0, 0.1);
}
.p-menu .p-submenu-header {
  margin: 0;
  padding: 0.5rem 0.75rem;
  color: #94a3b8;
  background: #ffffff;
  font-weight: 600;
  border-top-right-radius: 0;
  border-top-left-radius: 0;
}
.p-menu .p-menuitem-separator {
  border-top: 1px solid #e2e8f0;
  margin: 2px 0;
}
.p-menu .p-menuitem-badge {
  background: #3B82F6;
  color: #ffffff;
  font-size: 0.75rem;
  font-weight: 700;
  min-width: 1.5rem;
  height: 1.5rem;
  line-height: 1.5rem;
  border-radius: 6px;
  margin-left: 0.5rem;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
}
