.p-button .p-badge {
  margin-left: 0.5rem;
  min-width: 1rem;
  height: 1rem;
  line-height: 1rem;
  color: #3B82F6;
  background-color: #ffffff;
}

.p-badge {
  background: #3B82F6;
  color: #ffffff;
  font-size: 0.75rem;
  font-weight: 700;
  min-width: 1.5rem;
  height: 1.5rem;
  line-height: 1.5rem;
}
.p-badge.p-badge-secondary {
  background-color: #f1f5f9;
  color: #475569;
}
.p-badge.p-badge-success {
  background-color: #22c55e;
  color: #ffffff;
}
.p-badge.p-badge-info {
  background-color: #0ea5e9;
  color: #ffffff;
}
.p-badge.p-badge-warning {
  background-color: #f97316;
  color: #ffffff;
}
.p-badge.p-badge-danger {
  background-color: #ef4444;
  color: #ffffff;
}
.p-badge.p-badge-lg {
  font-size: 1.125rem;
  min-width: 2.25rem;
  height: 2.25rem;
  line-height: 2.25rem;
}
.p-badge.p-badge-xl {
  font-size: 1.5rem;
  min-width: 3rem;
  height: 3rem;
  line-height: 3rem;
}
