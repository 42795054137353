.p-dialog {
  width: 30%;
}
.p-dialog .p-dialog-header .p-dialog-title {
  @apply font-bold text-base;
}
.p-dialog .p-dialog-content {
  @apply font-normal text-sm;
}
@media screen and (max-width: 960px) {
  .p-dialog {
    width: initial;
  }
}
