.p-tabview .p-tabview-nav-content {
  scroll-padding-inline: 3rem;
}
.p-tabview .p-tabview-nav {
  background: #ffffff;
  border-color: #e5e7eb;
  border-style: solid;
  border-width: 0 0 2px 0;
}
.p-tabview .p-tabview-nav li {
  margin-right: 0;
}
.p-tabview .p-tabview-nav li .p-tabview-nav-link {
  @apply px-5 pt-0 pb-3 text-gray-800;
  border-style: solid;
  border-width: 0 0 2px 0;
  border-color: transparent transparent #e5e7eb transparent;
  background: #ffffff;
  font-weight: 700;
  border-top-right-radius: 6px;
  border-top-left-radius: 6px;
  transition: box-shadow 0.2s;
  margin: 0 0 -2px 0;
}
.p-tabview .p-tabview-nav li .p-tabview-nav-link:not(.p-disabled):focus-visible {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: inset 0 0 0 0.2rem #BFDBFE;
}
.p-tabview .p-tabview-nav li:not(.p-highlight):not(.p-disabled):hover .p-tabview-nav-link {
  background: #ffffff;
  border-color: #9ca3af;
}
.p-tabview .p-tabview-nav li.p-highlight .p-tabview-nav-link {
  background: #ffffff;
  border-color: #3B82F6;
}
.p-tabview .p-tabview-left-icon {
  margin-right: 0.5rem;
}
.p-tabview .p-tabview-right-icon {
  margin-left: 0.5rem;
}
.p-tabview .p-tabview-close {
  margin-left: 0.5rem;
}
.p-tabview .p-tabview-nav-btn.p-link {
  background: #ffffff;
  color: #3B82F6;
  width: 3rem;
  box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12);
  border-radius: 0;
}
.p-tabview .p-tabview-nav-btn.p-link:focus-visible {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: inset 0 0 0 0.2rem #BFDBFE;
}
.p-tabview .p-tabview-panels {
  background: #ffffff;
  padding: 1.25rem;
  border: 0 none;
  color: #4b5563;
  border-bottom-right-radius: 6px;
  border-bottom-left-radius: 6px;
}
.p-tabview .p-tabview-nav li .p-tabview-nav-link {
  transition: background-color 0.2s, border-color 0.2s, box-shadow 0.2s;
}

.p-tabmenu .p-tabmenu-nav {
  background: #ffffff;
  border-color: #e5e7eb;
  border-style: solid;
  border-width: 0 0 2px 0;
}
.p-tabmenu .p-tabmenu-nav .p-menuitem-badge {
  background: #3B82F6;
  color: #ffffff;
  font-size: 0.75rem;
  font-weight: 700;
  min-width: 1.5rem;
  height: 1.5rem;
  line-height: 1.5rem;
  border-radius: 6px;
  margin-left: 0.5rem;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
}
.p-tabmenu .p-tabmenu-nav .p-tabmenuitem {
  margin-right: 0;
}
.p-tabmenu .p-tabmenu-nav .p-tabmenuitem .p-menuitem-link {
  @apply px-5 pt-0 pb-3 text-gray-800 text-center text-base font-medium;
  border-style: solid;
  border-width: 0 0 2px 0;
  border-color: transparent transparent #e5e7eb transparent;
  background: #ffffff;
  border-top-right-radius: 6px;
  border-top-left-radius: 6px;
  transition: box-shadow 0.2s;
  margin: 0 0 -2px 0;
}
.p-tabmenu .p-tabmenu-nav .p-tabmenuitem .p-menuitem-link .p-menuitem-icon {
  margin-right: 0.5rem;
}
.p-tabmenu .p-tabmenu-nav .p-tabmenuitem .p-menuitem-link:not(.p-disabled):focus-visible {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: inset 0 0 0 0.2rem #BFDBFE;
}
.p-tabmenu .p-tabmenu-nav .p-tabmenuitem:not(.p-highlight):not(.p-disabled):hover .p-menuitem-link {
  background: #ffffff;
  border-color: #9ca3af;
}
.p-tabmenu .p-tabmenu-nav .p-tabmenuitem.p-highlight .p-menuitem-link {
  background: #ffffff;
  border-color: #3B82F6;
}
.p-tabmenu .p-tabmenu-left-icon {
  margin-right: 0.5rem;
}
.p-tabmenu .p-tabmenu-right-icon {
  margin-left: 0.5rem;
}
.p-tabmenu .p-tabmenu-nav-btn.p-link {
  background: #ffffff;
  color: #3B82F6;
  width: 3rem;
  box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12);
  border-radius: 0;
}
.p-tabmenu .p-tabmenu-nav-btn.p-link:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: inset 0 0 0 0.2rem #BFDBFE;
}
.p-tabmenu .p-tabmenu-nav .p-tabmenuitem .p-menuitem-link {
  transition: background-color 0.2s, border-color 0.2s, box-shadow 0.2s;
}
