
.p-avatar {
  background-color: #e2e8f0;
  border-radius: 6px;
}
.p-avatar.p-avatar-lg {
  width: 3rem;
  height: 3rem;
  font-size: 1.125rem;
}
.p-avatar.p-avatar-lg .p-avatar-icon {
  font-size: 1.125rem;
}
.p-avatar.p-avatar-xl {
  width: 4rem;
  height: 4rem;
  font-size: 1.5rem;
}
.p-avatar.p-avatar-xl .p-avatar-icon {
  font-size: 1.5rem;
}
.p-avatar.p-avatar-xxl {
  width: 5rem;
  height: 5rem;
  font-size: 1.875rem;
}
.p-avatar.p-avatar-xxl .p-avatar-icon {
  font-size: 1.875rem;
}

.p-avatar-group .p-avatar {
  border: 2px solid #ffffff;
}

.p-avatar {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 2rem;
  height: 2rem;
  font-size: 0.75rem;
  font-weight: 600;
}

.p-avatar.p-avatar-image {
  background-color: transparent
}

.p-avatar.p-avatar-circle {
  border-radius: 50%;
  overflow: hidden
}

.p-avatar .p-avatar-icon {
  font-size: 1rem
}

.p-avatar img {
  width: 100%;
  height: 100%
}
