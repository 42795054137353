.p-tooltip .p-tooltip-text {
  background: #334155;
  color: #ffffff;
  padding: 0.5rem 0.75rem;
  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -2px rgba(0, 0, 0, 0.1);
  border-radius: 6px;
}
.p-tooltip.p-tooltip-right .p-tooltip-arrow {
  border-right-color: #334155;
}
.p-tooltip.p-tooltip-left .p-tooltip-arrow {
  border-left-color: #334155;
}
.p-tooltip.p-tooltip-top .p-tooltip-arrow {
  border-top-color: #334155;
}
.p-tooltip.p-tooltip-bottom .p-tooltip-arrow {
  border-bottom-color: #334155;
}
