.p-overlaypanel {
  background: #ffffff;
  color: #334155;
  border: 1px solid #e2e8f0;
  border-radius: 6px;
  box-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.1), 0 8px 10px -6px rgba(0, 0, 0, 0.1);
}
.p-overlaypanel .p-overlaypanel-content {
  padding: 0 1.125rem 1.125rem 1.125rem;
}
.p-overlaypanel .p-overlaypanel-close {
  background: #3B82F6;
  color: #ffffff;
  width: 1.75rem;
  height: 1.75rem;
  transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s, outline-color 0.2s;
  border-radius: 50%;
  position: absolute;
  top: -0.875rem;
  right: -0.875rem;
}
.p-overlaypanel .p-overlaypanel-close:enabled:hover {
  background: #2563eb;
  color: #ffffff;
}
.p-overlaypanel:after {
  border: solid transparent;
  border-color: rgba(255, 255, 255, 0);
  border-bottom-color: #ffffff;
}
.p-overlaypanel:before {
  border: solid transparent;
  border-color: rgba(226, 232, 240, 0);
  border-bottom-color: #d3dce8;
}
.p-overlaypanel.p-overlaypanel-flipped:after {
  border-top-color: #ffffff;
}
.p-overlaypanel.p-overlaypanel-flipped:before {
  border-top-color: #e2e8f0;
}

.p-overlaypanel {
  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -2px rgba(0, 0, 0, 0.1);
}
.p-overlaypanel .p-overlaypanel-content {
  padding: 0.75rem;
}
.p-overlaypanel .p-overlaypanel-close {
  width: 1.75rem;
  height: 1.75rem;
  color: #64748b;
  border: 0 none;
  background: transparent;
  border-radius: 50%;
  transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s, outline-color 0.2s;
  position: absolute;
  top: 0.25rem;
  right: 0.25rem;
}
.p-overlaypanel .p-overlaypanel-close:enabled:hover {
  color: #475569;
  border-color: transparent;
  background: #f1f5f9;
}
.p-overlaypanel .p-overlaypanel-close:focus-visible {
  outline: 1px solid var(--p-focus-ring-color);
  outline-offset: 2px;
  box-shadow: none;
}

// The following implementation grabbed from PrimeNG's website: https://primeng.org/overlaypanel
.p-overlaypanel {
  position: absolute;
  margin-top: 10px;
  top: 0;
  left: 0
}

.p-overlaypanel-flipped {
  margin-top: 0;
  margin-bottom: 10px
}

.p-overlaypanel-close {
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  position: relative
}

.p-overlaypanel:after, .p-overlaypanel:before {
  bottom: 100%;
  left: calc(var(--overlayArrowLeft, 0) + 1.25rem);
  content: " ";
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none
}

.p-overlaypanel:after {
  border-width: 8px;
  margin-left: -8px
}

.p-overlaypanel:before {
  border-width: 10px;
  margin-left: -10px
}

.p-overlaypanel-shifted:after, .p-overlaypanel-shifted:before {
  left: auto;
  right: 1.25em;
  margin-left: auto
}

.p-overlaypanel-flipped:after, .p-overlaypanel-flipped:before {
  bottom: auto;
  top: 100%
}

.p-overlaypanel.p-overlaypanel-flipped:after {
  border-bottom-color: transparent
}

.p-overlaypanel.p-overlaypanel-flipped:before {
  border-bottom-color: transparent
}
