.p-radiobutton {
  width: 1.25rem;
  height: 1.25rem;
}
.p-radiobutton .p-radiobutton-box {
  border: 1px solid #cbd5e1;
  background: #ffffff;
  width: 1.25rem;
  height: 1.25rem;
  color: #334155;
  border-radius: 50%;
  transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s, outline-color 0.2s;
  outline-color: transparent;
}
.p-radiobutton .p-radiobutton-box:not(.p-disabled):not(.p-highlight):hover {
  border-color: #94a3b8;
}
.p-radiobutton .p-radiobutton-box:not(.p-disabled).p-focus {
  outline: 1px solid var(--p-focus-ring-color);
  outline-offset: -1px;
  box-shadow: none;
  border-color: #94a3b8;
}
.p-radiobutton .p-radiobutton-box .p-radiobutton-icon {
  width: 0.75rem;
  height: 0.75rem;
  transition-duration: 0.2s;
  background-color: #3B82F6;
}
.p-radiobutton .p-radiobutton-box.p-highlight {
  border-color: #3B82F6;
  background: #ffffff;
}
.p-radiobutton .p-radiobutton-box.p-highlight:not(.p-disabled):hover {
  border-color: #2563eb;
  background: #ffffff;
  color: #2563eb;
}

p-radiobutton.ng-dirty.ng-invalid > .p-radiobutton > .p-radiobutton-box {
  border-color: #f87171;
}

.p-input-filled .p-radiobutton .p-radiobutton-box {
  background-color: #f8fafc;
}
.p-input-filled .p-radiobutton .p-radiobutton-box:not(.p-disabled):hover {
  background-color: #f8fafc;
}
.p-input-filled .p-radiobutton .p-radiobutton-box.p-highlight {
  background: #ffffff;
}
.p-input-filled .p-radiobutton .p-radiobutton-box.p-highlight:not(.p-disabled):hover {
  background: #ffffff;
}

.p-radiobutton-label {
  margin-left: 0.5rem;
}

.p-radiobutton .p-radiobutton-box {
  box-shadow: 0 0 #0000, 0 0 #0000, 0 1px 2px 0 rgba(18, 18, 23, 0.05);
}
.p-radiobutton .p-radiobutton-box.p-focus {
  outline-offset: 2px;
}
.p-radiobutton .p-radiobutton-box.p-focus.p-highlight {
  border-color: #3B82F6;
}
.p-radiobutton.p-radiobutton-disabled {
  opacity: 1;
}
.p-radiobutton.p-radiobutton-disabled .p-radiobutton-box {
  border: 1px solid #cbd5e1;
  background-color: #e2e8f0;
}
.p-radiobutton.p-radiobutton-disabled .p-radiobutton-box .p-radiobutton-icon {
  background-color: #64748b;
}
