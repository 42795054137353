.p-checkbox {
  width: 1.25rem;
  height: 1.25rem;
}
.p-checkbox .p-checkbox-box {
  border: 1px solid #cbd5e1;
  background: #ffffff;
  width: 1.25rem;
  height: 1.25rem;
  color: #334155;
  border-radius: 6px;
  transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s, outline-color 0.2s;
  outline-color: transparent;
}
.p-checkbox .p-checkbox-box .p-checkbox-icon {
  transition-duration: 0.2s;
  color: #ffffff;
  font-size: 0.875rem;
}
.p-checkbox .p-checkbox-box .p-icon {
  width: 0.875rem;
  height: 0.875rem;
}
.p-checkbox .p-checkbox-box.p-highlight {
  border-color: #3B82F6;
  background: #3B82F6;
}
.p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box:hover {
  border-color: #94a3b8;
}
.p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box.p-focus {
  outline: 1px solid var(--p-focus-ring-color);
  outline-offset: -1px;
  box-shadow: none;
  border-color: #94a3b8;
}
.p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box.p-highlight:hover {
  border-color: #2563eb;
  background: #2563eb;
  color: #ffffff;
}

p-checkbox.ng-dirty.ng-invalid > .p-checkbox > .p-checkbox-box {
  border-color: #f87171;
}

.p-input-filled .p-checkbox .p-checkbox-box {
  background-color: #f8fafc;
}
.p-input-filled .p-checkbox .p-checkbox-box.p-highlight {
  background: #3B82F6;
}
.p-input-filled .p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box:hover {
  background-color: #f8fafc;
}
.p-input-filled .p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box.p-highlight:hover {
  background: #2563eb;
}

.p-checkbox-label {
  margin-left: 0.5rem;
}

p-tristatecheckbox.ng-dirty.ng-invalid > .p-checkbox > .p-checkbox-box {
  border-color: #f87171;
}
