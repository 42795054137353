.mat-table, .mat-mdc-table {
  @apply table-fixed;
  @apply border border-gray-200 border-solid #{!important};
  @apply rounded-xl border-separate overflow-hidden font-sans text-sm;

  .mat-mdc-row, .mat-row {
    @apply font-sans h-[40px];
    .mat-mdc-cell-content {
      @apply flex items-center gap-1 overflow-hidden;
    }

    &.row-selected {
      @apply bg-gray-50 #{!important};
    }
  }
  > mat-header-row, .mat-mdc-header-row {
    @apply bg-gray-100 h-[44px] min-h-[44px] #{!important};

    mat-header-cell, .mat-mdc-header-cell {
      @apply font-sans font-semibold;
    }
  }

}

mat-cell:first-of-type,
.mat-mdc-cell:first-of-type,
mat-header-cell:first-of-type,
.mat-mdc-header-cell:first-of-type,
mat-footer-cell:first-of-type,
.mat-mdc-footer-cell:first-of-type {
  @apply pl-4 #{!important};
}
