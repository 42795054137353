
.p-card {
  background: #ffffff;
  color: #334155;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px -1px rgba(0, 0, 0, 0.1);
  border-radius: 6px;
  display: flex;
  flex-direction: column;
}
.p-card .p-card-body {
  padding: 1.5rem;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}
.p-card .p-card-title {
  @apply text-base font-medium mb-2;
}
.p-card .p-card-subtitle {
  font-weight: 400;
  margin-bottom: 0.5rem;
  color: #64748b;
}
.p-card .p-card-content {
  @apply p-0 font-normal;
}
.p-card .p-card-footer {
  padding: 0 0 0 0;
}

.p-card .p-card-caption {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}
.p-card .p-card-caption .p-card-title,
.p-card .p-card-caption .p-card-subtitle {
  margin-bottom: 0;
}
